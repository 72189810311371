import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment";

@Pipe({
  name: 'localTime'
})
export class LocalTimePipe implements PipeTransform {

  transform(slot: object): string {
    const from =  moment.utc(slot['from']).local().format("HH:mm")
    const to = moment.utc(slot['to']).local().format("HH:mm")

    return `${to} - ${from}`
  }

}
