import { Injectable } from '@angular/core';

@Injectable()

export class ConvertNumberService {

  convertPersianNumbersToEnglish(string) {
    if (!string) return string;
    return string.replace(/[\u0660-\u0669]/g, function (c) {
      return c.charCodeAt(0) - 0x0660;
    }).replace(/[\u06f0-\u06f9]/g, function (c) {
      return c.charCodeAt(0) - 0x06f0;
    })
  }

  convertEnglishNumbersToPersian(string: string) {
    if (!string) return string;
    return string.replace(/0/g,'٠').replace(/1/g,'١').replace(/2/g,'٢').replace(/3/g,'٣').replace(/4/g,'٤')
        .replace(/5/g,'٥').replace(/6/g,'٦').replace(/7/g,'٧').replace(/8/g,'٨').replace(/9/g,'٩')
        .replace(/۰/g,'٠').replace(/۱/g,'١').replace(/۲/g,'٢').replace(/۳/g,'٣').replace(/۴/g,'٤')
        .replace(/۵/g,'٥').replace(/۶/g,'٦').replace(/۷/g,'٧').replace(/۸/g,'٨').replace(/۹/g,'٩');
  }

}
