// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { generate } from 'rxjs';

// local environment

export const environment = {
  production: false,
  siteUrl: "https://dadmin.isos.clinic",
  baseUrl: "https://dhub.isos.clinic",
  identityUrl: "https://didentity.isos.clinic",
  fileUrl: "https://dkernel.isos.clinic/api/Minio",
  consultantUrl: "https://dconsultant.isos.clinic/api/v1",
  apiUrl: "https://ddoctor.isos.clinic/api/v1",
  patientUrl: "https://dpatient.isos.clinic/api/v1",
  scheduleUrl: "https://dschedule.isos.clinic/api/v1",
  walletUrl: "https://dwallet.isos.clinic/api/v1",
  sharedUrl: "https://dkernel.isos.clinic/api",
  emailUrl: "https://dkernel.isos./api/Email",
  graphUrl: "https://dbff.isos.clinic/graphql",
  client_id: 'admin',
  client_secret: 'secret'
};

// export const environment = {
//   production: false,
//   siteUrl: "https://sfront.isos.clinic",
//   apiUrl: "https://sdoctor.isos.clinic/api/v1",
//   patientUrl: "https://spatient.isos.clinic/api/v1",
//   consultantUrl: "https://sconsultant.isos.clinic/api/v1",
//   scheduleUrl: "https://schedule.isos.clinic/api/v1",
//   identityUrl: "https://sidentity.isos.clinic",
//   walletUrl: "https://swallet.isos.clinic/api/v1",
//   graphUrl: "https://sbff.isos.clinic/graphql",
//   baseUrl: "https://shub.isos.clinic",
//   fileUrl: "https://skernel.isos.clinic/api/StoreFile",
//   sharedUrl: "https://skernel.isos.clinic/api",
//   emailUrl: "https://skernel.isos.clinic/api/Email",
//   client_id: "myId",
//   client_secret: "secret",
// };

// export const environment = {
//   production: false,
//   siteUrl: 'https://develop.isos.clinic', // 'http://localhost:4200',
//   baseUrl: 'https://hub.isos.clinic',
//   identityUrl: 'https://identity.isos.clinic',
//   fileUrl: 'https://kernel.isos.clinic/api/StoreFile',
//   consultantUrl: 'https://consultant.isos.clinic/api/v1',
//   apiUrl: 'https://doctor.isos.clinic/api/v1',
//   patientUrl: 'https://patient.isos.clinic/api/v1',
//   scheduleUrl: 'https://schedule.isos.clinic/api/v1',
//   walletUrl: 'https://wallet.isos.clinic/api/v1',
//   sharedUrl: 'https://kernel.isos.clinic/api',
//   emailUrl: 'https://kernel.isos./api/Email',
//   graphUrl: 'https://bff.isos.clinic/graphql',
//   client_id: 'myId',
//   client_secret: 'secret'
// };

// export const environment = {
//   production: false,
//   siteUrl: 'http://localhost:4200',
//   baseUrl: 'https://localhost:44371',
//   identityUrl: 'https://localhost:2001',
//   fileUrl: 'http://localhost:11000/s/api/StoreFile',
//   consultantUrl: 'https://localhost:44360/api/v1',
//   apiUrl: 'https://localhost:3011/api/v1',
//   patientUrl:'https://localhost:9001/api/v1',
//   scheduleUrl: 'https://localhost:44388/api/v1',
//   walletUrl: 'https://localhost:4001/api/v1',
//   sharedUrl: 'https://localhost:44318/api',
//   emailUrl: 'http://localhost:11000/s/api/Email',
//   graphUrl: 'https://localhost:5001/graphql',
//   client_id: 'myId',
//   client_secret: 'secret'
// }

// export const environment = {
//   production: false,
//   identityUrl: 'https://gate.isos.clinic/i',
//   fileUrl: 'https://gate.isos.clinic/s/api/StoreFile',
//   consultantUrl: 'https://gate.isos.clinic/c/api/v1',
//   apiUrl: 'https://gate.isos.clinic/d/api/v1',
//   patientUrl:'https://gate.isos.clinic/p/api/v1',
//   scheduleUrl: 'https://gate.isos.clinic/sch/api/v1',
//   walletUrl: 'https://gate.isos.clinic/w/api/v1',
//   client_id: 'myId',
//   client_secret: 'secret'
// }

// RTCPeerConfiguration: {
//   iceServers: [
//     {
//       urls: 'stun:fr-turn1.xirsys.com'
//     },
//     {
//       urls: [
//         "turn:fr-turn1.xirsys.com:80?transport=udp",
//         "turn:fr-turn1.xirsys.com:3478?transport=udp",
//         "turn:fr-turn1.xirsys.com:80?transport=tcp",
//         "turn:fr-turn1.xirsys.com:3478?transport=tcp",
//         "turns:fr-turn1.xirsys.com:443?transport=tcp",
//         "turns:fr-turn1.xirsys.com:5349?transport=tcp"
//       ],
//       username: "i1zor_76mh66ElRsExmFcUQECSuhGBlc1sDUdkU_BjsIk2INQ6_Wbgrn3vnZI3AsAAAAAGMBvAFTb21heWVoTWFqaWRp",
//       credential: "39a83ee4-210e-11ed-b579-0242ac120004",
//     }
//   ]
// }

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
