<div class="w-100">
    <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ label }} </mat-label>
        <input
            *ngIf="!isPassword"
            matInput
            [type]="type"
            [mask]="format"
            [dropSpecialCharacters]="isDrop"
            [placeholder]="placeholder"
            [formControl]="control" >
        <input
            *ngIf="isPassword"
            appBlockCopy
            matInput
            [type]="type"
            [mask]="format"
            [dropSpecialCharacters]="isDrop"
            [placeholder]="placeholder"
            [formControl]="control" >
        <mat-icon matSuffix *ngIf="isPassword" class="pointer" (click)="onShowPassword()">
            {{ show ? 'visibility_off' : 'visibility' }}
        </mat-icon>
        <mat-hint *ngIf="control.touched">
            <p class="form-errors" *ngIf="isPassword && control?.errors?.['pattern']">
                {{ label }}
                باید شامل حروف کوچک و بزرگ و عدد باشد.
            </p>
            <p class="form-errors" *ngIf="isPassword && control?.errors?.['pattern']">
                {{ label }}
                باید شامل حداقل ۸ کاراکتر باشد.
            </p>
            <p class="form-errors" *ngIf="control?.errors?.['required']">
                {{ label }}
                الزامی است.
            </p>
            <p class="form-errors" *ngIf="control?.errors?.['minlength']">
                {{ label }}
                باید دارای حداقل
                {{ control.errors?.['minlength'].requiredLength  }}
                حرف باشد. شما
                {{ control.errors?.['minlength'].actualLength }}
                حرف وارد کرده اید.
            </p>
            <p class="form-errors" *ngIf="control?.errors?.['maxlength']">
                {{ label }}
                نباید بیشتر از
                {{ control.errors?.['maxlength'].requiredLength  }}
                حرف باشد. شما
                {{ control.errors?.['maxlength'].actualLength }}
                حرف وارد کرده اید.
            </p>
            <p class="form-errors" *ngIf="control?.errors?.['pattern']">
                مقدار وارد شده برای
                {{ label }}
                معتبر نمی باشد.
            </p>
            <p class="form-errors" *ngIf="control?.errors?.['min']">
                {{ label }}
                کمتر از حداقل مقدار است.
            </p>
            <p class="form-errors" *ngIf="control?.errors?.['max']">
                {{ label }}
                بیشتر از حداکثر مقدار است.
            </p>
            <p class="form-errors" *ngIf="control?.errors?.['email']">
                {{ label }}
                وارد شده صحیح نمی باشد.
            </p>
            <p class="form-errors" *ngIf="control?.errors?.['englishNumber']">
                لطفا
                {{ label }}
                را با اعداد انگلیسی وارد کنید.
            </p>
            <p class="form-errors" *ngIf="control?.errors?.['noMatch']">
                کلمه عبور
                وارد شده مطابقت ندارد.
            </p>
            <p class="form-errors" *ngIf="form?.errors?.['timeRangeInvalid']">
                ساعت شروع نمی تواند قبل از ساعت پایان باشد.
            </p>
            <p class="form-errors" *ngIf="control?.errors?.['invalidNationalCode']">
                {{ label }}
                وارد شده معتبر نمی باشد.
            </p>
        </mat-hint>
    </mat-form-field>
</div>
