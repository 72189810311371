import {Pipe, PipeTransform} from '@angular/core';
import { ConvertNumberService } from '../services/convert-number.service'

@Pipe({
    name: 'enNum'
})

export class EnNumPipe implements PipeTransform {

    constructor(private persianNumberService: ConvertNumberService) {
    }

    transform(value: string | number): string {
        const strValue = String(value);
        return this.persianNumberService.convertEnglishNumbersToPersian(strValue)
    }
}
