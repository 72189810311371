import {Component, OnInit} from '@angular/core';
import {AuthService} from "./shared/services/auth.service";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'sos-admin-panel';
  isAuthenticated = true;
  isLoginRoute = false;
  user: any;

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    ) {
    this.translate.setDefaultLang('fa');
    this.authService.user.subscribe(user => {
      if (user) {
        this.isAuthenticated = true;
      } else {
        this.isAuthenticated = false;
      }
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isLoginRoute = this.router.url.includes('login');
      }
    });
  }

  ngOnInit() {
    this.authService.autoSignIn();
  }
}
