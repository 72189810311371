<div class="w-100 p-0 d-flex flex-column rounded overflow-hidden bg-white" style="min-height: 225px;" dir="rtl">
    <div  >
        <div class="d-flex align-items-center justify-content-between bg-success-light p-3" mat-dialog-title *ngIf="data?.dialogType === 'success'">
            <h4 class="mb-0">{{ data?.title }}</h4>
            <span (click)="cancel()" class="pointer">
                <i class="fa fa-close"></i>
            </span>
        </div>

        <div class="d-flex align-items-center justify-content-between bg-danger-light p-3" mat-dialog-title *ngIf="data?.dialogType === 'error'">
            <h4 class="mb-0">{{ data?.title }}</h4>
            <span (click)="cancel()" class="pointer">
                <i class="fa fa-close"></i>
            </span>
        </div>

        <div class="my-3 p-3 "  mat-dialog-content>
            <h4 style="font-size: 14px;">{{data.message}}</h4>
        </div>
    </div>

    <div mat-dialog-actions class="d-flex align-items-center justify-content-end p-3">
        <button class="btn btn-primary m-1" (click)="confirm()" *ngIf="data?.confirmButtonType === 'success'">
            <span>
                <i class="fa fa-arrow-left"></i>
            </span>
            <span class="mx-1">
                {{ data?.confirmButtonText }}
            </span>
        </button>
        <button class="btn btn-danger m-1" (click)="confirm()" *ngIf="data?.confirmButtonType === 'error'">
            <span>
                <i class="fa fa-close"></i>
            </span>
            <span class="mx-1">
                {{ data?.confirmButtonText }}
            </span>
        </button>
        <button class="btn btn-outline-danger m-1" (click)="cancel()">{{ data?.cancelButtonText }}</button>
    </div>

</div>
